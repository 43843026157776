import { createSlice } from "@reduxjs/toolkit";

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    status: "idle",
    token: null,
    error: null,
  },
  reducers: {
    startRegistration(state) {
      state.status = "registering";
      state.token = null;
      state.error = null;
    },
    registrationSuccess(state, action) {
      state.status = "success";
      state.token = action.payload.token;
      state.error = null;
    },
    registrationFailed(state, action) {
      state.status = "error";
      state.token = null;
      state.error = action.payload;
    },
  },
});

export const { startRegistration, registrationFailed, registrationSuccess } =
  registrationSlice.actions;

export default registrationSlice.reducer;
