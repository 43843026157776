import { createSlice } from "@reduxjs/toolkit";
import { Navigate } from "react-router-dom";

const cookies = document.cookie.split(";").reduce((prev, curr) => {
  const [name, value] = curr.trim().split("=");
  prev[name] = value;
  return prev;
}, {});
const authSlice = createSlice({
  name: "auth",
  initialState: {
    username: localStorage.getItem("email"),
    loading: false,
    error: null,
    isLoggedIn: cookies.loggedIn,
    userRole: null,
    userTasks: cookies.Tasks,
    isAdmin: cookies.isAdmin === "true" ? true : false,
    hasProfile: null,
    isEmployee: cookies.isEmployee === "true" ? true : false,
    isJudge: cookies.isJudge === "true" ? true : false,
    isCoachMentor: cookies.isCoachMentor === "true" ? true : false,
    isDeptHead: false,
  },
  reducers: {
    login_Start(state) {
      state.loading = true;
      state.error = null;
    },
    login_Success(state, action) {
      state.authToken = action.payload;
      state.userRole = action.payload.userRole;
      state.userTasks = action.payload.userTasks;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.isAdmin = action.payload.isAdmin;
      state.hasProfile = action.payload.hasProfile;
      state.isEmployee = action.payload.isEmployee;
      state.isJudge = action.payload.isJudge;
      state.isCoachMentor = action.payload.isCoachMentor;
      state.isDeptHead = action.payload.isDeptHead;
      // document.cookie = `loggedIn=true; expires=0; path=/;`;
    },
    login_Failure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.authToken = null;

      state.userRole = null;
      state.userTasks = null;

      localStorage.removeItem("RememberMe");
      localStorage.removeItem("SavedEmail");
      localStorage.removeItem("SavedPassword");
      sessionStorage.clear();

      state.isLoggedIn = false;
      state.isAdmin = false;
      state.isEmployee = false;
      state.isJudge = false;
      state.isCoachMentor = false;
      state.isDeptHead = false;
      document.cookie = `SavedEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `loggedIn=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `FirstName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `LastName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `RoleID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `RoleName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `isAdmin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `isEmployee=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `Tasks=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `profileChecker=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `CurrentOnboardingEmployee=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `PrequaTableView=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `PrequaTablePage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `JobPositionsPage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `JobPositionsView=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `isCoachMentor=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `isJudge=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // document.cookie = `Redirect=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

      // const fetchData = async () => {
      //   try {
      //     const response = await fetch(
      //       "https://97.74.95.183/api/Login/Logout",
      //       {
      //         method: "POST",
      //         headers: {
      //           "Content-Type": "application/json",
      //         },
      //         mode: "cors",
      //         credentials: "include",
      //         body: JSON.stringify({}),
      //       }
      //     );
      //     // const jsonData = await response.json();
      //     console.log(response);
      //   } catch (error) {
      //     console.log("Error fetching data:", error);
      //   }
      // };
      // fetchData();
    },
  },
});

export const { login_Start, login_Success, login_Failure, logout } =
  authSlice.actions;

export default authSlice.reducer;
