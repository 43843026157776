import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  isSubmitting: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};

const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    submitForgetPassword: (state) => {
      state.isSubmitting = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    forgetPasswordSuccess: (state) => {
      state.isSubmitting = false;
      state.isSuccess = true;
    },
    forgetPasswordError: (state, action) => {
      state.isSubmitting = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    clearForgetPasswordState: (state) => {
      state.email = "";
      state.isSubmitting = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
  },
});

export const {
  setEmail,
  submitForgetPassword,
  forgetPasswordSuccess,
  forgetPasswordError,
  clearForgetPasswordState,
} = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;
