import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import registerReducer from "./auth/registerSlice";
import forgotPasswordReducer from "./auth/forgotPassword";
import resetPasswordReducer from "./auth/resetPasswordSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    register: registerReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
  },
});

export default store;
