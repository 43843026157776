import { createSlice } from "@reduxjs/toolkit";

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    Token: localStorage.getItem("token"),
  },
  reducers: {
    setResetPasswordToken: (state, action) => {
      state.Token = action.payload;
      localStorage.setItem("token", action.payload);
    },
  },
});

export const { setResetPasswordToken } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
